import React, { FC } from 'react';
import s from './WarehousesInChina.module.scss'
import { IDeliveryPoints } from '../../../../store/modules';
import { useTranslation } from 'react-i18next';

interface WProps {
  delivery_points: IDeliveryPoints[] | undefined
}

const WarehousesInChina: FC<WProps> = ({ delivery_points }) => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  return (
    <section className={`container ${s.warehouses_in_china}`}>
      <div className={s.card_city}>
        {
          delivery_points && delivery_points.length > 0 &&
          delivery_points.map((el, i) => (
            <div key={el.id} className={s.card}>
              <img src={el.delivery_point_image.startsWith('http')
                ? `https${el.delivery_point_image.slice(4)}`
                : el.delivery_point_image
              } alt="blue" />
              <span className={i % 2 ? s.green : s.blue}>
                {el?.[`delivery_point_title_${lang}`]}
              </span>
            </div>
          ))
        }
      </div>
    </section>
  );
};

export default WarehousesInChina;