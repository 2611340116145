import React, { FC, useEffect } from 'react';
import s from './Partner.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { fetchByHomeInfo } from '../../../store/slice/infoSlice';
import { pathLink } from '../../../reused';


const Partner: FC = () => {
    const { home } = useAppSelector(state => state.info)
    const { aboutUsInfo } = useAppSelector(state => state.aboutUsTs)
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()
    const lang = i18n.language;

    useEffect(() => {
        dispatch(fetchByHomeInfo())
    }, [dispatch])

    return (
        <div className={s.partner}>
            <h2 className={s.title}>{home?.[`trust_us_title_${lang}`]}</h2>
            <div className={`container ${s.partnerBox}`}>
                {
                    aboutUsInfo && aboutUsInfo[0]?.brand_images?.length > 0 &&
                    aboutUsInfo[0].brand_images.map(el => (
                        <div key={el.id}>
                            <img className={s.img}
                                src={el?.brand_image.startsWith('http') ? `https${el?.brand_image.slice(4)}` : pathLink + el?.brand_image}
                                alt="brand" />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Partner;