import React, { FC, useEffect, useRef, useState } from 'react';
import s from './BtnWrapper.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { fetchByServiceInfo } from '../../../store/slice/infoSlice';

const BtnWrapper: FC = () => {
    const [newId, setNewId] = useState<string>('');
    const { service } = useAppSelector(state => state.info);
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const btnBoxRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [mouseX, setMouseX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        dispatch(fetchByServiceInfo());
    }, [dispatch]);

    useEffect(() => {
        if (service && service.length > 0) {
            setNewId([...service].sort((a, b) => a.order - b.order)[0].id);
        }
    }, [service]);

    const handleButtonClick = (id: string) => {
        if (id !== newId) {
            setNewId(id);
        }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        setMouseX(event.clientX);
        setScrollLeft(btnBoxRef.current?.scrollLeft || 0);
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging) return;
        const delta = mouseX - event.clientX;
        if (btnBoxRef.current) {
            btnBoxRef.current.scrollLeft = scrollLeft + delta;
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const activeService = service?.find(el => el.id === newId);

    return (
        <div className={`container ${s.btnWrapp}`}>
            <div
                ref={btnBoxRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                className={`${s.btnBox}`}>
                {service && service.length > 0 && [...service].sort((a, b) => a.order - b.order).map(el => (
                    <button
                        key={el.id}
                        className={s.btnContent}
                        onClick={() => handleButtonClick(el.id)}
                        style={{
                            backgroundColor: el.id === newId ? 'rgb(108, 140, 199)' : 'rgb(208, 213, 221)',
                        }}
                    >
                        {el[`service_name_${lang}`]}
                    </button>
                ))}
            </div>
            {activeService && (
                <div className={`container ${s.detailBox}`}>
                    <div className={s.btnItem}>
                        <h2 className={s.title}>{activeService[`service_name_${lang}`]}</h2>
                        <p className={s.paragraf}>
                            {activeService[`service_description_${lang}`] || 'No description available.'}
                        </p>
                    </div>
                    <div className={s.img}>
                        <img src={activeService?.service_banner_image.startsWith('http')
                            ? `https${activeService?.service_banner_image.slice(4)}` : activeService?.service_banner_image}
                            alt="banner" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default BtnWrapper;
