import React, { FC } from 'react';
import s from './CargoCard.module.scss'
import { ICargoCard } from '../../../store/modules';
import { useTranslation } from 'react-i18next';

const CargoCard: FC<ICargoCard> = (el) => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  return (
    <div className={s.cargo_card}>
      <div className={s.img_in_cargo_card}>
        <img src={el.card_image.startsWith('http')
          ? `https${el.card_image.slice(4)}`
          : el.card_image
        }
          alt={el?.[`card_title_${lang}`]} />
      </div>
      <h2>{el?.[`card_title_${lang}`]}</h2>
      <span>{el?.[`card_description_${lang}`]} </span>
    </div>
  );
};

export default CargoCard;
