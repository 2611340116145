import React, { FC, useEffect } from 'react';
import s from './SoutheastAsia.module.scss'
import WarehousesInChina from './WarehousesInChina/WarehousesInChina';
import SouthKorea from './SouthKorea/SouthKorea';
import Delivery from './Delivery/Delivery';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByAsia } from '../../../store/slice/servicesSlice';
import { useTranslation } from 'react-i18next';
import AboutCompanySwiper from '../../AboutCompany/AboutCompanySwiper/AboutCompanySwiper';

const SoutheastAsia: FC = () => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const lang = i18n.language
  const { asia } = useAppSelector(state => state.service)

  useEffect(() => {
    dispatch(fetchByAsia())
  }, [dispatch])
  return (
    <div className={s.asia}>
      <WarehousesInChina delivery_points={asia?.delivery_points_china} />
      <SouthKorea />
      <Delivery />
      <div className={`container ${s.s_w_asia}`}>
        <h2>{asia?.[`delivery_info_title_${lang}`]} </h2>
        <p>{asia?.[`delivery_info_description_${lang}`]} </p>
        < AboutCompanySwiper video={asia?.reviews_video} />
      </div>
    </div>
  );
};

export default SoutheastAsia;
