import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GaleryModules, IMagesGaleryModules, VideoGaleryModules } from '../modules'
import { storesApi } from '../../axios'

type InitialState = {
    loading: boolean
    error: null | string | undefined
    galery: GaleryModules | null
    galeryCard: IMagesGaleryModules[]
    video: VideoGaleryModules | null
}

const initialState: InitialState = {
    error: null,
    loading: false,
    galery: null,
    galeryCard: [],
    video: null
}

export const fetchByGalery = createAsyncThunk<GaleryModules, void, { rejectValue: string }>(
    'galery/fetchByGalery', async (_, { rejectWithValue }) => {
        const res = await storesApi.getGalery()
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data[0] as GaleryModules
    })

export const fetchByVideo = createAsyncThunk<VideoGaleryModules, void, { rejectValue: string }>(
    'galery/fetchByVideo', async (_, { rejectWithValue }) => {
        const res = await storesApi.getVIdeo()
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data[0] as VideoGaleryModules
    })

const GalerySlice = createSlice({
    name: 'galery',
    initialState,
    reducers: {

    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByGalery.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByGalery.fulfilled, (state, action) => {
            state.galery = action.payload
            state.galeryCard = action.payload.photo_gallery_images.reverse()
            state.loading = false
        })

        addCase(fetchByGalery.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'Упс что-то пошло не так попробуйте снова!'
            } else {
                state.error = action.payload
            }
        })
        addCase(fetchByVideo.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByVideo.fulfilled, (state, action) => {
            state.video = action.payload
            state.loading = false
        })

        addCase(fetchByVideo.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'Упс что-то пошло не так попробуйте снова!'
            } else {
                state.error = action.payload
            }
        })
    },
})

export default GalerySlice.reducer
