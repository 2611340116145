import React, { FC, useEffect } from 'react';
import s from './ModalAboutUs.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { setVideoComment } from '../../../store/slice/AboutUsSlice';

const ModalAboutUs: FC = () => {
    const { linkVideo, videoComment } = useAppSelector(state => state.aboutUsTs)
    const dispatch = useAppDispatch()
    const handleVideo = () => {
        dispatch(setVideoComment(false))
    }


    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && handleVideo()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (videoComment) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => handleVideo();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!videoComment) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    return (
        <div className={s.Confirmation} onClick={handleVideo}>
            <iframe
                className={s.box}
                onClick={e => e.stopPropagation()}
                src={linkVideo.slice(0, 24) + 'embed/' + linkVideo.slice(32) + '?autoplay=1'}
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                frameBorder="0"
            ></iframe>
        </div>
    );
};

export default ModalAboutUs;