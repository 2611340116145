import { configureStore } from '@reduxjs/toolkit'
import infoSlice from './slice/infoSlice'
import servicesSlice from './slice/servicesSlice'
import AboutUsSlice from './slice/AboutUsSlice'
import GalerySlice from './slice/GalerySlice'


export const store = configureStore({
  reducer: {
    info: infoSlice,
    service: servicesSlice,
    aboutUsTs: AboutUsSlice,
    galeryTs: GalerySlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
