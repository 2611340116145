import React, { FC, useEffect } from 'react';
import s from './AboutCompany.module.scss'
import AboutCompanySwiper from './AboutCompanySwiper/AboutCompanySwiper';
import { useAppSelector } from '../../store/hooks/hooks';
import FirstDiv from './FirstDiv'
import SecondDiv from './SecondDiv';
import ThreeDiv from './ThreeDiv';
import { pathLink } from '../../reused';
import { useTranslation } from 'react-i18next'
import Loading from '../../components/Loading/Loading';
import { Helmet } from 'react-helmet-async';

const AboutCompany: FC = () => {
  const { i18n, t } = useTranslation()
  const lang = i18n.language
  const { aboutUsInfo, loading } = useAppSelector(state => state.aboutUsTs)

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  return (
    <>
      <Helmet>
        <meta property="og:title" content={lang === 'ru' ? 'О компании | Caravan' : lang === 'eng' ? 'About company | Caravan' : '關於公司 | Caravan'} />
        <meta name="twitter:title" content={lang === 'ru' ? 'О компании | Caravan' : lang === 'eng' ? 'About company | Caravan' : '關於公司 | Caravan'} />
        <link rel="canonical" href={`https://caravan-logistics.com/about_company`} />
        <title>
          {lang === 'ru' ? 'О компании | Caravan' : lang === 'eng' ? 'About company | Caravan' : '關於公司 | Caravan'}
        </title>
      </Helmet>
      <div className={s.About}>
        <div className='container'>
          {
            aboutUsInfo.length > 0 && aboutUsInfo.map((el) => (
              <div key={el.id}>
                < div className={s.display} >
                  <FirstDiv lang={lang} subdir={el.about_page_banners[0]} />
                  <div className={s.column}>
                    <SecondDiv lang={lang} subdirTwo={el.about_page_banners[1]} />
                    <ThreeDiv lang={lang} subdirThree={el.about_page_banners[2]} />
                  </div>
                </div>
                <h2 className={s.doverie}>{t("about_us")}</h2>
                <div className={s.vera}>
                  {el.brand_images.map((el, i) => (
                    <img
                      key={i}
                      src={el.brand_image.startsWith('http')
                        ? `https${el?.brand_image.slice(4)}`
                        : pathLink + el?.brand_image}
                      alt="" />
                  ))
                  }
                </div>
              </div>
            ))
          }
        </div >
        {
          aboutUsInfo.length > 0 && aboutUsInfo[0].reviews_video.length && aboutUsInfo.map((el, i) => (
            <div key={i} className={`container ${s.about}`}>
              <h2>{el?.[`about_title_${lang}`]}</h2>
              <p>
                {el?.[`about_description_${lang}`]}
              </p>
              {
                el.reviews_video.length > 0
                && aboutUsInfo.map(el => < AboutCompanySwiper key={el.id} video={el.reviews_video} />)
              }
            </div>
          ))
        }
      </div >
      {loading && <Loading />}
    </>
  );
};

export default AboutCompany;