import React, { FC, useEffect, useState } from 'react';
import './Acor.scss';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { fetchByQuistionInfo } from '../../../../store/slice/infoSlice';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'block',
    },
    padding: '32px'
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={props.expandIcon}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(255, 255, 255, .03)',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transition: 'transform 0.3s',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Acor: FC = () => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const { quest } = useAppSelector(state => state.info);
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    const lang = i18n.language;

    useEffect(() => {
        dispatch(fetchByQuistionInfo());
    }, [dispatch]);

    return (
        <div className='acor'>
            {
                quest && quest?.add_questions?.length > 0 &&
                quest?.add_questions?.map((el) => (
                    <Accordion
                        key={el.id}
                        expanded={expanded === `panel${el.id}`}
                        onChange={handleChange(`panel${el.id}`)}
                    >
                        <AccordionSummary
                            aria-controls={`panel${el.id}-content`}
                            id={`panel${el.id}-header`}
                            expandIcon={expanded === `panel${el.id}` ? <RemoveIcon /> : <AddIcon />}
                        >
                            <h2 id='Atitle'>{el.id}</h2>
                            <h3 id='Asubtitle'>{el[`add_question_title_${lang}`]}</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p id='Aparagraf'>{el[`add_question_answer_${lang}`]}</p>
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    );
};

export default Acor;
