import React, { FC, useEffect } from 'react';
import s from './Main.module.scss'
import { Route, Routes } from 'react-router-dom';
import AboutCompany from '../../pages/AboutCompany/AboutCompany';
import Home from '../../pages/Home/Home';
import Gallery from '../../pages/Gallery/Gallery';
import Services from '../../pages/Services/Services';
import NotFound from '../../pages/NotFound/NotFound';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAllServices } from '../../store/slice/servicesSlice';
import { fetchByAboutUs } from '../../store/slice/AboutUsSlice';
import ModalAboutUs from '../../pages/AboutCompany/ModalAboutUs/ModalAboutUs';
import ServiceCargo from '../../pages/ServiceCargo/ServiceCargo';

const Main: FC = () => {
  const { videoComment } = useAppSelector(state => state.aboutUsTs)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchByAllServices())
    dispatch(fetchByAboutUs())
  }, [dispatch])

  return (
    <>
      <main className={s.main}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about_company' element={<AboutCompany />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/services' element={<Services />} />
          <Route path='/service_cargo' element={<ServiceCargo />} />
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </main>
      {videoComment && <ModalAboutUs />}
    </>
  );
};

export default Main;