import React, { FC, useEffect, useState } from 'react';
import './Header.scss'
import { NavLink, useSearchParams } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import logo from '../../Assets/Home/logo.png'
import BurgerMenu from './BurgerMenu/BurgerMenu';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAllInfo } from '../../store/slice/infoSlice';
import ServiceDetail from './ServiceDetail/ServiceDetail';
import Language from './Language/Language';

const Header: FC = () => {
  const [isActiveBurger, setIsActiveBurger] = useState<boolean>(false);
  const [service, setService] = useState<boolean>(false);
  const { allInfo } = useAppSelector(state => state.info)
  const [searchParams] = useSearchParams()
  const [query, setQuery] = useState(searchParams.get('id'))
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const navToggle = (): void => {
    setIsActiveBurger(!isActiveBurger);
  };

  const navSerToggle = (): void => {
    setService(!service)
  }

  useEffect(() => {
    dispatch(fetchByAllInfo())
  }, [dispatch])

  useEffect(() => {
    setQuery(searchParams.get('id'))
  }, [searchParams])
  return (
    <>
      <header>
        <nav className='container'>
          <div className='menuBox'>
            <NavLink to={'/'}>
              <img className='logo' src={logo} alt="" />
            </NavLink>
            <ul className='menu'>
              <li> <NavLink to={'/about_company'} className={({ isActive }) =>
                isActive ? 'nav_link active_nav' : 'nav_link'
              } >{t('header.about_us')}</NavLink></li>
              <li><span onClick={navSerToggle} className={query ? 'nav_link active_nav' : 'nav_link'}>{t('header.services')}</span></li>
              <li><ScrollLink to="footer" smooth={true} duration={500} className='nav_link'>{t('header.contact')}</ScrollLink></li>
              <li><NavLink to={'/gallery'} className={({ isActive }) =>
                isActive ? 'nav_link active_nav' : 'nav_link'
              }>{t('header.gallery')}</NavLink></li>
            </ul>
          </div>
          <div className="custom-select">
            <div className="number_box">
              <Language />
              <a target="_blank" rel="noreferrer" href={`tel:${allInfo?.phone_number}`} className='btnnumber'>{allInfo?.phone_number}</a>
            </div>
            <div onClick={navToggle} className={isActiveBurger ? 'nav_toogler toogle' : 'nav_toogler'}>
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </div>
          </div>
        </nav>
      </header>
      <BurgerMenu isActiveBurger={isActiveBurger} setIsActiveBurger={setIsActiveBurger} />
      {service && <ServiceDetail service={service} setService={setService} />}
    </>
  );
};

export default Header;