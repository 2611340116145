import React, { FC, useEffect } from 'react';
import s from './Footer.module.scss'
import logo from '../../Assets/Home/flogo.png'
import wh from '../../Assets/Home/social.png'
import insta from '../../Assets/Home/inicon.png'
import tel from '../../Assets/Home/communication.png'
import { Link, NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAllInfo } from '../../store/slice/infoSlice';
import { useTranslation } from 'react-i18next';
import bitriks from '../../Assets/bitriks.png'

const Footer: FC = () => {
  const { allInfo } = useAppSelector(state => state.info)
  const dispatch = useAppDispatch()
  const { i18n, t } = useTranslation()
  const lang = i18n.language;

  useEffect(() => {
    dispatch(fetchByAllInfo())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <footer id='footer'>
      <div className={`container ${s.content}`}>
        <div className={s.card}>
          <NavLink to={'/'}>
            <img className={s.logo} src={logo} alt="" />
          </NavLink>
          <p className={s.paragraf}>{t('footer.paragraf')}</p>
        </div>
        <div className={s.card}>
          <h2 className={s.title}>{t('footer.address')}</h2>
          <a href={`https://2gis.kg/bishkek/search/${encodeURIComponent(`${allInfo?.address_ru}`)}`} target='_blank' rel="noreferrer" className={s.paragraf}>{allInfo?.[`address_${lang}`]}</a>
          <a target="_blank" rel="noreferrer" href={`tel:${allInfo?.phone_number}`} className={s.paragraf}>{allInfo?.phone_number}</a>
        </div>
        <div className={s.card}>
          <h2 className={s.title}>{t('footer.soci')}</h2>
          <div className={s.icons}>
            {allInfo?.whatsapp &&
              <Link target="_blank" rel="noreferrer" to={"https://wa.me/" + allInfo?.whatsapp}>
                <img className={s.img} src={wh} alt="" />
              </Link>
            }
            {allInfo?.telegram &&
              <Link target="_blank" rel="noreferrer" to={allInfo?.telegram.startsWith('https') ? allInfo?.telegram : `https://t.me/${allInfo?.telegram.startsWith('+') ? allInfo?.telegram : allInfo?.telegram.startsWith('@') ? `https://t.me/${allInfo?.telegram.slice(1)}` : (allInfo && +allInfo?.telegram) ? '+' + allInfo?.telegram : allInfo?.telegram}`}>
                <img className={s.img} src={tel} alt="" />
              </Link>
            }
            {allInfo?.instagram &&
              <Link target="_blank" rel="noreferrer" to={'https://www.instagram.com/' + allInfo?.instagram}>
                <img className={s.img} src={insta} alt="" />
              </Link>
            }
          </div>
          <a href={`mailto:${allInfo?.email}`} className={s.paragraf}>{allInfo?.email}</a>
          <a className={s.bitrix_link} target='_blank' rel="noreferrer noopener" href="https://crm.caravan-logistics.com">
            <img src={bitriks} alt="битрикс" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;