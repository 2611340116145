import React, { FC, useState } from 'react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { VideoBlocksModules } from '../../../store/modules';
import './GalerySwiper.scss'
import GaleryModal from './GaleryModal/GaleryModal';
interface videoFirstIn {
    videoFirst: VideoBlocksModules | undefined
    lang: string
}
const GalerySwiperFIrst: FC<videoFirstIn> = ({ videoFirst }) => {
    const [videoLink, setVideoLink] = useState('')
    const [videoModal, setVideoModal] = useState(false)

    const handleVideo = (id: number) => {
        videoFirst?.video_links.filter(el => el.id === id && setVideoLink(el.video_gallery_link))
        setVideoModal(true)
    }

    return (
        <>
            <div className='AboutSwiper'>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={window.screen.width < 767 ? 1 : videoFirst && videoFirst?.video_links.length <= 2 ? 2 : 3}
                    initialSlide={videoFirst && videoFirst?.video_links.length < 3 ? 0 : window.screen.width < 767 ? 0 : 1}
                    navigation={window.screen.width < 767 ? false : true}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={{
                        clickable: true
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="mySwiper">
                    {
                        videoFirst && videoFirst?.video_links.length > 0 && videoFirst?.video_links.map((el, i) => (
                            <SwiperSlide key={i}>
                                <div
                                    className='iframe-container-div'
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onClick={() => handleVideo(el.id)}>
                                    <iframe src={el?.video_gallery_link.slice(0, 24) + 'embed/' + el?.video_gallery_link.slice(32)} allowFullScreen />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            {videoModal && <GaleryModal videoLink={videoLink} videoModal={videoModal} setVideoModal={setVideoModal} />}
        </>
    );
};

export default GalerySwiperFIrst;