import chNs1 from './locales/ch/translation.json'
import ruNs1 from './locales/ru/translation.json'
import enNs1 from './locales/eng/translation.json'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const defaultNS = 'translation'

const resources = {
  cn: {
    translation: chNs1
  },
  ru: {
    translation: ruNs1
  },
  eng: {
    translation: enNs1
  },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'ru',
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
    resources,
    detection: {
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
    },
  })

export default i18n
