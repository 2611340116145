import axios from 'axios'
import { pathLink } from '../reused'

const instance = axios.create({
  baseURL: pathLink,
  headers: {
    'Content-Type': 'application/json',
  },
})


export const storesApi = {
  getAllStores() {
    return instance.get('')
  },
  getAdvantages() {
    return instance.get('/api/advantages/advantages/')
  },
  getServices(id: string) {
    return instance.get(`/api/services_pages/services_pages/${id}/`)
  },
  getAllServices() {
    return instance.get(`/api/services_pages/services_pages/`)
  },
  getAsia() {
    return instance.get(`/api/delivery_from_sa/delivery_from_sa/`)
  },
  getAboutUs() {
    return instance.get('/api/about_page/about_page/')
  },
  getGalery() {
    return instance.get('/api/gallery/photo_gallery/')
  },
  getVIdeo() {
    return instance.get('/api/gallery/video_gallery/')
  },
  getAllInfo() {
    return instance.get('/api/information/company-information/')
  },
  getHomeInfo() {
    return instance.get('/api/main_page/main-page/')
  },
  getQuestionInfo() {
    return instance.get('/api/questions/questions/')
  },
  getServiceInfo() {
    return instance.get('/api/service/service/')
  },
  getCargoPage() {
    return instance.get('/api/cargo/cargo-caravan/')
  }
}
