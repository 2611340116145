import React, { FC } from 'react';
import { IMagesGaleryModules } from '../../store/modules';
import { useTranslation } from 'react-i18next';
import s from './Gallery.module.scss'
import '../Gallery/GalerySwiper/GalerySwiper.scss'
import defoltImg from '../../Assets/AboutCompany/Frame 1000003377 (1).png'
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
const GaleryCard: FC<IMagesGaleryModules> = (el) => {
    const { i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={s.Card}>
            <LightGallery
                selector='.gallery_item'
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
                <img className='gallery_item'
                    src={el?.photo_gallery_image
                        ? el?.photo_gallery_image.startsWith('http')
                            ? `https${el?.photo_gallery_image.slice(4)}` : el?.photo_gallery_image : defoltImg}
                    alt={el?.[`photo_gallery_image_title_${lang}`]} />
            </LightGallery>
            <h3>{el?.[`photo_gallery_image_title_${lang}`]}</h3>
            <h4>{el?.[`photo_gallery_image_description_${lang}`]}</h4>
        </div>
    );
};

export default GaleryCard;