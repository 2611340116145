import React, { FC } from 'react';
import s from './AboutCompany.module.scss'
import { AboutPageBanners } from '../../store/modules';
interface SecondIN {
    subdirTwo: AboutPageBanners
    lang: string
}
const SecondDiv: FC<SecondIN> = ({ subdirTwo, lang }) => {
    return (
        <div className={s.secondDiv}>
            <img
                src={subdirTwo.about_page_banner_image.startsWith('http')
                    ? `https${subdirTwo.about_page_banner_image.slice(4)}`
                    : subdirTwo.about_page_banner_image
                }
                alt={subdirTwo?.[`about_page_banner_text_${lang}`]} />
            <p>{subdirTwo?.[`about_page_banner_text_${lang}`]}</p>
        </div>
    );
};

export default SecondDiv;