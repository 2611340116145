import React, { FC } from 'react';
import s from './Advantages.module.scss';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import AboutCompanySwiper from '../../../AboutCompany/AboutCompanySwiper/AboutCompanySwiper';

const Advantages: FC = () => {
  const { aboutUsInfo } = useAppSelector(state => state.aboutUsTs);
  const { advantages } = useAppSelector(state => state.service);
  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <section className={`container ${s.advantages}`}>
      <h2>{advantages?.[`title_${lang}`]}</h2>
      <div className={s.cards_in_advantages}>
        {advantages && advantages.advantages_detail.length > 0 && advantages.advantages_detail.map(el => (
          <div key={el.id} className={s.card}>
            <img src={el.advantages_image.startsWith('http')
              ? `https${el.advantages_image.slice(4)}`
              : el.advantages_image
            } alt="one" />
            <div className={s.text_in_card}>
              <h3>{el?.[`advantages_title_${lang}`]}</h3>
              <p>{el?.[`advantages_description_${lang}`]}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={s.about_us_in}>
        <h2>{aboutUsInfo[0]?.[`about_title_${lang}`]} </h2>
        <p>{aboutUsInfo[0]?.[`about_description_${lang}`]} </p>
        <AboutCompanySwiper video={aboutUsInfo[0]?.reviews_video} />
      </div>
    </section>
  );
};

export default Advantages;