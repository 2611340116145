import React, { FC, useEffect, useState } from 'react';
import s from './Services.module.scss'
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAdvantages, fetchByInfoPageServices } from '../../store/slice/servicesSlice';
import Banner from './section/Banner/Banner';
import MenuService from './section/MenuService/MenuService';
import Advantages from './section/Advantages/Advantages';
import { useTranslation } from 'react-i18next';
import SoutheastAsia from './SoutheastAsia/SoutheastAsia';
import Loading from '../../components/Loading/Loading';
import { fetchByAboutUs } from '../../store/slice/AboutUsSlice';
import { Helmet } from 'react-helmet-async';

const Services: FC = () => {
  const dispatch = useAppDispatch()
  const { i18n, t } = useTranslation()
  const lang = i18n.language
  const [searchParams, setSearchParams] = useSearchParams()
  const [query, setQuery] = useState(searchParams.get('id'))
  const { service_page, loading } = useAppSelector(state => state.service)

  useEffect(() => {
    setQuery(searchParams.get('id'))
  }, [searchParams])

  useEffect(() => {
    query &&
      dispatch(fetchByInfoPageServices(query))
    dispatch(fetchByAdvantages())
  }, [dispatch, query])

  useEffect(() => {
    dispatch(fetchByAboutUs())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [searchParams.get('id'), dispatch])

  return (
    <>
      <Helmet>
        <meta property="og:title" content={`${service_page?.[`service_page_title_${lang}`]} | Caravan`} />
        <meta name="twitter:title" content={`${service_page?.[`service_page_title_${lang}`]} | Caravan`} />
        <link rel="canonical" href={`https://caravan-logistics.com/services?id=${query}`} />
        <title>
          {`${service_page?.[`service_page_title_${lang}`]} | Caravan`}
        </title>
      </Helmet>
      <div className={s.service}>
        <Banner
          text_button={t("Services.ContactSpecialist")}
          img={service_page?.service_page_banner}
          text={service_page?.[`service_page_title_${lang}`]}
          description={service_page?.[`service_page_description_${lang}`]} />
        <MenuService
          add_info={service_page?.service_page_content}
          query={query}
          setSearchParams={setSearchParams} />
        {service_page?.service_page_title_ru.includes('Юго-восточная Азия') ? <SoutheastAsia /> : <Advantages />}
      </div>
      {loading && <Loading />}
    </>
  );
};

export default Services;