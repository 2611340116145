import React, { FC, useEffect } from 'react';
import s from './ServiceCargo.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByAdvantages, fetchByCargo } from '../../store/slice/servicesSlice';
import { useTranslation } from 'react-i18next';
import CargoCard from './CargoCard/CargoCard';
import AboutCompanySwiper from '../AboutCompany/AboutCompanySwiper/AboutCompanySwiper';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

const ServiceCargo: FC = () => {
  const dispatch = useAppDispatch()
  const { cargo, advantages } = useAppSelector(state => state.service)
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    dispatch(fetchByCargo())
    dispatch(fetchByAdvantages())
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [dispatch])

  // useEffect(() => {
  //   window.scrollTo({
  //     behavior: 'smooth',
  //     top: 0
  //   })
  // }, [window.location.pathname])

  return (
    <div className={s.cargo_page}>
      <Helmet>
        <meta property="og:title" content={`${t("header.cargo")} | Caravan`} />
        <meta name="twitter:title" content={`${t("header.cargo")} | Caravan`} />
        <link rel="canonical" href={`https://caravan-logistics.com/service_cargo`} />
        <title>
          {`${t("header.cargo")} | Caravan`}
        </title>
      </Helmet>
      <section className={s.banner}>
        <img className={s.background}
          src={cargo?.main_banner_image.startsWith('http')
            ? `https${cargo?.main_banner_image.slice(4)}`
            : cargo?.main_banner_image
          }
          alt={cargo?.[`main_banner_text_${lang}`]} />
        <div className="container">
          <div className={` ${s.text_in_banner}`}>
            <h2>{cargo?.[`main_banner_text_${lang}`]}</h2>
          </div>
        </div>
      </section>
      <section className={`container ${s.cargo_cards}`}>
        <h2>{cargo?.[`sub_banner_text_${lang}`]} </h2>
        <div className={s.cards_field}>
          {cargo && cargo.cargo_card.length > 0 &&
            cargo.cargo_card.map(el => (
              <CargoCard key={el.id} {...el} />
            ))}
        </div>
      </section>
      <section className={`container ${s.advantages}`}>
        <h2>{advantages?.[`title_${lang}`]}</h2>
        <div className={s.cards_in_advantages}>
          {advantages && advantages.advantages_detail.length > 0 && advantages.advantages_detail.map(el => (
            <div key={el.id} className={s.card}>
              <img
                src={el.advantages_image.startsWith('http')
                  ? `https${el.advantages_image.slice(4)}`
                  : el.advantages_image
                }
                alt="one" />
              <div className={s.text_in_card}>
                <h3>{el?.[`advantages_title_${lang}`]}</h3>
                <p>{el?.[`advantages_description_${lang}`]}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={s.about_us_in}>
          <h2>CARAVAN CARGO</h2>
          <p>{t("Services.CargoTitle")} </p>
          <AboutCompanySwiper video={cargo?.cargo_video} />
        </div>
      </section>
    </div>
  );
};

export default ServiceCargo;