import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { storesApi } from '../../axios'
import { CargoPage, IAdvantages, IAsia, IInfoPageService } from '../modules'

type InitialState = {
  loading: boolean
  error: null | string | undefined
  advantages: IAdvantages | null
  service_page: IInfoPageService | null
  all_service: IInfoPageService[]
  asia: IAsia | null
  cargo: CargoPage | null
}

const initialState: InitialState = {
  error: null,
  loading: false,
  advantages: null,
  service_page: null,
  all_service: [],
  asia: null,
  cargo: null
}

export const fetchByAdvantages = createAsyncThunk<IAdvantages, void, { rejectValue: string }>(
  'profile/fetchByAdvantages',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getAdvantages()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as IAdvantages
  }
)

export const fetchByInfoPageServices = createAsyncThunk<IInfoPageService, string, { rejectValue: string }>(
  'profile/fetchByInfoPageServices',
  async (id, { rejectWithValue }) => {
    const res = await storesApi.getServices(id)
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data as IInfoPageService
  }
)

export const fetchByAllServices = createAsyncThunk<IInfoPageService[], void, { rejectValue: string }>(
  'profile/fetchByAllServices',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getAllServices()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data
  }
)

export const fetchByAsia = createAsyncThunk<IAsia, void, { rejectValue: string }>(
  'profile/fetchByAsia',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getAsia()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as IAsia
  }
)

export const fetchByCargo = createAsyncThunk<CargoPage, void, { rejectValue: string }>(
  'profile/fetchByCargo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getCargoPage()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as CargoPage
  }
)

const servicesSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {

  },
  extraReducers: ({ addCase }) => {
    addCase(fetchByAdvantages.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByAdvantages.fulfilled, (state, action) => {
      state.advantages = action.payload
      state.loading = false
    })

    addCase(fetchByAdvantages.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
    addCase(fetchByInfoPageServices.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByInfoPageServices.fulfilled, (state, action) => {
      state.service_page = action.payload
      state.loading = false
    })

    addCase(fetchByInfoPageServices.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
    addCase(fetchByAllServices.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByAllServices.fulfilled, (state, action) => {
      state.all_service = action.payload
      state.loading = false
    })

    addCase(fetchByAllServices.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
    addCase(fetchByAsia.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByAsia.fulfilled, (state, action) => {
      state.asia = action.payload
      state.loading = false
    })

    addCase(fetchByAsia.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
    addCase(fetchByCargo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByCargo.fulfilled, (state, action) => {
      state.cargo = action.payload
      state.loading = false
    })

    addCase(fetchByCargo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
    // ======================
  },
})
// export const {} = servicesSlice.actions

export default servicesSlice.reducer
