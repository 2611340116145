import React, { FC, useEffect } from 'react';
import s from './OurServices.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { fetchByServiceInfo } from '../../../store/slice/infoSlice';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import './OurServices.scss'

const OurServices: FC = () => {
    const { service } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()
    const { i18n, t } = useTranslation()
    const lang = i18n.language;

    useEffect(() => {
        dispatch(fetchByServiceInfo())
    }, [dispatch])

    return (
        <div className={s.ourService}>
            <h2 className={s.title}>{t('header.ourservice')}</h2>
            <div className={`container ${s.OurServicesBox}`}>
                {
                    service && service?.length > 0 &&
                    [...service].sort((a, b) => a.order - b.order).map(el => (
                        <Link to={`/services?id=${el?.service_pages.length > 0 && el?.service_pages[0].id}`} className={s.card} key={el.id}>
                            <img className={s.img}
                                src={el?.service_image.startsWith('http')
                                    ? `https${el?.service_image.slice(4)}` : el?.service_image}
                                alt={el.service_name_eng} />
                            <div className={s.content}>
                                <h2 className={s.paragraf}>{el?.[`service_name_${lang}`]}</h2>
                                <span className={s.line}></span>
                            </div>
                        </Link>
                    ))
                }
            </div>
            <div className='swiper_in_our_service'>
                <Swiper navigation={false} pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                    modules={[Pagination, Navigation]} className="mySwiper">
                    {
                        service && service?.length > 0 &&
                        [...service].sort((a, b) => a.order - b.order).map(el => (
                            <SwiperSlide className='cards_in_our_service' key={el.id}>
                                <Link to={`/services?id=${el?.service_pages.length > 0 && el?.service_pages[0].id}`} className='card_in_our_service'>
                                    <img className='img'
                                        src={el?.service_image.startsWith('http')
                                            ? `https${el?.service_image.slice(4)}` : el?.service_image}
                                        alt={el.service_name_eng} />
                                    <div className='content'>
                                        <h2 className='paragraf'>{el?.[`service_name_${lang}`]}</h2>
                                        <span className='line'></span>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>

    );
};

export default OurServices;