import React, { FC, useEffect } from 'react';
import s from './GaleryModal.module.scss'
interface IGaleryModal {
    videoLink: string
    videoModal: boolean
    setVideoModal: (value: boolean) => void
}
const GaleryModal: FC<IGaleryModal> = ({ setVideoModal, videoLink, videoModal }) => {

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && setVideoModal(false)
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (videoModal) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => setVideoModal(false);
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!videoModal) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    return (
        <div className={s.Confirmation} onClick={() => setVideoModal(false)}>
            <iframe
                className={s.box}
                onClick={e => e.stopPropagation()}
                src={videoLink.slice(0, 24) + 'embed/' + videoLink.slice(32) + '?autoplay=1'}
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                frameBorder="0"

            ></iframe>
        </div>
    );
};

export default GaleryModal;