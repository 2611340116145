import React, { FC } from 'react';
import s from './Delivery.module.scss'
import { useAppSelector } from '../../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import '../SwiperInService.scss'

const Delivery: FC = () => {
  const { asia } = useAppSelector(state => state.service)
  const { i18n } = useTranslation()
  const lang = i18n.language
  return (
    <section className={`container ${s.delivery}`}>
      <h2>{asia?.[`delivery_step_title_${lang}`]} </h2>
      <div className={s.delivery_card}>
        {
          asia && asia.delivery_steps.length > 0 &&
          asia.delivery_steps.map(el => (
            <div key={el.id} className={s.card}>
              <img src={el.delivery_steps_image.startsWith('http')
                ? `https${el.delivery_steps_image.slice(4)}`
                : el.delivery_steps_image
              } alt="Delivery1" />
              <h3>{el?.[`delivery_steps_title_${lang}`]} </h3>
            </div>
          ))
        }
      </div>
      <div className='swiper_in_service'>
        <Swiper navigation={false}
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          className="mySwiper">
          {
            asia && asia?.delivery_steps.length > 0 &&
            asia?.delivery_steps.map(el => (
              <SwiperSlide className='card' key={el.id}>
                <div key={el.id} className='card_delivery'>
                  <img className='delivery_steps_image'
                    src={el.delivery_steps_image.startsWith('http')
                      ? `https${el.delivery_steps_image.slice(4)}`
                      : el.delivery_steps_image}
                    alt="Delivery1" />
                  <h3>{el?.[`delivery_steps_title_${lang}`]} </h3>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </section>
  );
};

export default Delivery;