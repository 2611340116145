import React, { FC, useEffect, useState } from 'react';
import s from './ServiceDetail.module.scss'
import { useTranslation } from 'react-i18next';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks/hooks';

interface ServiceProps {
    setService: (e: boolean) => void
    service: boolean
}

const ServiceDetail: FC<ServiceProps> = ({ service, setService }) => {
    const hadleVisib = () => {
        setService(false)
    }
    const [searchParams] = useSearchParams()
    const [query, setQuery] = useState(searchParams.get('id'))
    const { all_service } = useAppSelector(state => state.service)
    const { i18n, t } = useTranslation()
    const lang = i18n.language

    useEffect(() => {
        setQuery(searchParams.get('id'))
    }, [searchParams])

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && hadleVisib()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (service) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => hadleVisib();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!service) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    return (
        <div onClick={hadleVisib} className={s.Burger}>
            <ul onClick={(e) => e.stopPropagation()} className={s.BurgerMenu}>
                {
                    all_service.length > 0 &&
                    [...all_service].sort((a, b) => a.order - b.order).map(el => (
                        <li key={el.id} onClick={hadleVisib}><NavLink className={({ isActive }) =>
                            +el.id === (query && +query) ? `${s.menu_link} ${s.menu_link_active}` : s.menu_link
                        } to={`/services?id=${el.id}`} >{el?.[`service_page_title_${lang}`]}</NavLink></li>
                    ))
                }
                <li onClick={hadleVisib}><NavLink to={`/service_cargo?id=0`} className={({ isActive }) =>
                    isActive ? `${s.menu_link} ${s.menu_link_active}` : s.menu_link
                }>{t("header.cargo")}</NavLink></li>
            </ul>
        </div>
    );
};

export default ServiceDetail;