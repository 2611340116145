import React, { FC, useEffect } from 'react';
import s from './ContactIcons.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { Link } from 'react-router-dom';
import wh from '../../../../Assets/Home/wsicon.png'
import tel from '../../../../Assets/Home/insicon.png'
import ins from '../../../../Assets/Home/telicon.png'
import { fetchByAllInfo } from '../../../../store/slice/infoSlice';


interface Private {
    setContact: (e: boolean) => void
    contact: boolean
}

const ContactIcons: FC<Private> = ({ contact, setContact }) => {

    const hideModal = () => {
        setContact(false)
    }

    const { allInfo } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByAllInfo())
    }, [dispatch])

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', e => {
            e.code === 'Escape' && hideModal()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (contact) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => setContact(false)
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!contact) window.history.back()
            window.onpopstate = () => { }
        }
    }, [])

    return (
        <div onClick={hideModal} className={s.wrapper}>
            <div className={s.Contactbox} onClick={e => e.stopPropagation()}>
                <div className={s.content}>
                    {
                        allInfo?.whatsapp &&
                        <Link target="_blank" rel="noreferrer" to={"https://wa.me/" + allInfo?.whatsapp}>
                            <div className={s.whs}>
                                <h2 className={s.title}>whatsapp</h2>
                                <img className={s.img} src={wh} alt="" />
                            </div>
                        </Link>
                    }
                    {
                        allInfo?.telegram &&
                        <Link target="_blank" rel="noreferrer" to={allInfo?.telegram.startsWith('https') ? allInfo?.telegram : `https://t.me/${allInfo?.telegram.startsWith('+') ? allInfo?.telegram : allInfo?.telegram.startsWith('@') ? `https://t.me/${allInfo?.telegram.slice(1)}` : (allInfo && +allInfo?.telegram) ? '+' + allInfo?.telegram : allInfo?.telegram}`}>
                            <div className={s.tel}>
                                <h2 className={s.title}>telegram</h2>
                                <img className={s.img} src={ins} alt="" />
                            </div>
                        </Link>
                    }
                    {
                        allInfo?.instagram &&
                        <Link target="_blank" rel="noreferrer" to={'https://www.instagram.com/' + allInfo?.instagram}>
                            <div className={s.inst}>
                                <h2 className={s.title}>instagram</h2>
                                <img className={s.img} src={tel} alt="" />
                            </div>
                        </Link>
                    }
                </div>
            </div>
        </div >
    );
};

export default ContactIcons;