import React, { FC, useEffect } from 'react';
import s from './Bghome.module.scss'
import { fetchByHomeInfo } from '../../../store/slice/infoSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';


const Bghome: FC = () => {
    const { home } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()
    const lang = i18n.language;

    useEffect(() => {
        dispatch(fetchByHomeInfo())
    }, [dispatch])

    return (
        <div
            style={{
                backgroundImage: ` url(${home?.main_banner_image.startsWith('http')
                    ? `https${home?.main_banner_image.slice(4)}` : home?.main_banner_image})`
            }}
            className={s.bgHome}>
            <div className={`container ${s.content}`}>
                <h2 className={s.title}>{home?.[`main_banner_text_${lang}`]}</h2>
                <h3 className={s.subtitle}>{home?.[`main_banner_sub_text_${lang}`]} </h3>
            </div>
        </div>
    );
};

export default Bghome;