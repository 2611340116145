import React, { FC } from 'react';
import s from './AboutCompany.module.scss'
import { AboutPageBanners } from '../../store/modules';
interface ThreeIn {
    subdirThree: AboutPageBanners
    lang: string
}
const ThreeDiv: FC<ThreeIn> = ({ subdirThree, lang }) => {
    return (
        <div className={s.secondDiv}>
            <img
                src={
                    subdirThree.about_page_banner_image.startsWith('http')
                        ? `https${subdirThree.about_page_banner_image.slice(4)}`
                        : subdirThree.about_page_banner_image
                }
                alt={subdirThree?.[`about_page_banner_text_${lang}`]} />
            <p>{subdirThree?.[`about_page_banner_text_${lang}`]}</p>
        </div>
    );
};

export default ThreeDiv;