import React, { FC } from 'react';
import s from './Language.module.scss'
import eng from '../../../Assets/Home/eng.gif'
import ru from '../../../Assets/Home/ru.gif'
import ch from '../../../Assets/Home/ch.gif'
import { useTranslation } from 'react-i18next';

const Language: FC = () => {
    const { i18n } = useTranslation()
    return (
        <div className={s.LanguageBox}>
            <img onClick={e => i18n.changeLanguage('ru')} className={i18n.language === 'ru' ? s.img_active : s.img} src={ru} alt="ru" />
            <img onClick={e => i18n.changeLanguage('eng')} className={i18n.language === 'eng' ? s.img_active : s.img} src={eng} alt="eng" />
            <img onClick={e => i18n.changeLanguage('cn')} className={i18n.language === 'cn' ? s.img_active : s.img} src={ch} alt="cn" />
        </div>
    );
};

export default Language;