import React, { FC, useEffect, useState } from 'react';
import s from './Acordion.module.scss'
import Acor from './Acor/Acor';
import ContactIcons from '../Bghome/ContactIcons/ContactIcons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByQuistionInfo } from '../../../store/slice/infoSlice';

const Acordion: FC = () => {
    const { quest } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()
    const [contact, setContact] = useState<boolean>(false);
    const { i18n, t } = useTranslation()
    const lang = i18n.language;

    useEffect(() => {
        dispatch(fetchByQuistionInfo())
    }, [dispatch])
    return (
        <div className={s.Acordion}>
            <div className={`container ${s.acorBox}`}>
                <div className={s.leftContent}>
                    <h2 className={s.title}>{quest?.[`title_${lang}`]}</h2>
                    <p className={s.paragraf}>{quest?.[`description_${lang}`]}</p>
                    <button onClick={() => setContact(!contact)} className={s.btn}>{t('header.btn')}</button>
                </div>
                <div className={s.rightContent}>
                    <Acor />
                </div>
            </div>
            {contact && <ContactIcons contact={contact} setContact={setContact} />}
        </div>
    );
};

export default Acordion;