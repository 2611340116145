import React, { FC, useState } from 'react';
import s from './Banner.module.scss'
import ContactIcons from '../../../../components/Section/Bghome/ContactIcons/ContactIcons';

interface IText {
  text: string
  description: string
  img: string | undefined
  text_button: string
}

const Banner: FC<IText> = ({ description, text, img, text_button }) => {
  const [contact, setContact] = useState(false)

  return (
    <>
      <section className={s.banner}>
        <img className={s.background}
          src={img?.startsWith('http') ? `https${img.slice(4)}` : img}
          alt={text} />
        <div className={`container ${s.text_in_banner}`}>
          <h2>{text}</h2>
          <p>{description}</p>
          <button onClick={() => setContact(!contact)}>{text_button} </button>
        </div>
      </section>
      {contact && <ContactIcons contact={contact} setContact={setContact} />}
    </>
  );
};

export default Banner; 