import React, { FC } from 'react';
import s from './SouthKorea.module.scss'
import { useAppSelector } from '../../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import '../SwiperInService.scss'

const SouthKorea: FC = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  const { asia } = useAppSelector(state => state.service)
  return (
    <section className={`container ${s.south_korea}`}>
      <h2>{asia?.[`delivery_china_title_${lang}`]}</h2>
      <div className={s.cards_in_south_korea}>
        {
          asia && asia?.delivery_cards.length > 0 &&
          asia?.delivery_cards.map(el => (
            <div key={el.id} className={s.card}>
              <img
                className={s.images}
                src={el.delivery_card_image.startsWith('http')
                  ? `https${el.delivery_card_image.slice(4)}`
                  : el.delivery_card_image
                }
                alt="Vector1" />
              <h3>{el?.[`delivery_card_title_${lang}`]} </h3>
              <p>{el?.[`delivery_card_description_${lang}`]} </p>
            </div>
          ))
        }
      </div>
      <div className='swiper_in_service'>
        <Swiper navigation={false}
          modules={[Navigation, Pagination]}
          className="mySwiper"
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}>
          {
            asia && asia?.delivery_cards.length > 0 &&
            asia?.delivery_cards.map(el => (
              <SwiperSlide className='card' key={el.id}>
                <div className='card_swiper'>
                  <img src={el.delivery_card_image.startsWith('http')
                    ? `https${el.delivery_card_image.slice(4)}`
                    : el.delivery_card_image
                  } alt="Vector1" />
                  <h3>{el?.[`delivery_card_title_${lang}`]} </h3>
                  <p>{el?.[`delivery_card_description_${lang}`]} </p>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
      <h2>{asia?.[`delivery_korea_title_${lang}`]}</h2>
      <h3>{asia?.[`delivery_korea_sub_title_${lang}`]}</h3>
      <div className={s.card_city}>
        {
          asia && asia.delivery_points_korea.length > 0 &&
          asia.delivery_points_korea.map((el, i) => (
            <div key={el.id} className={s.card}>
              <img src={el.delivery_point_image.startsWith('http')
                ? `https${el.delivery_point_image.slice(4)}`
                : el.delivery_point_image
              } alt="blue" />
              <span className={i % 2 ? s.green : s.blue}>
                {el?.[`delivery_point_title_${lang}`]}
              </span>
            </div>
          ))
        }
      </div>
    </section>
  );
};

export default SouthKorea;