import React, { FC, useState } from 'react';
import s from './MenuService.module.scss'
import { NavLink, SetURLSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { IAddInfo } from '../../../../store/modules';
import { SlArrowDown } from "react-icons/sl";

interface IMenuServiceProps {
  setSearchParams: SetURLSearchParams
  query: string | null
  add_info: IAddInfo[] | undefined
}
const MenuService: FC<IMenuServiceProps> = ({ query, setSearchParams, add_info }) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [upText, setUpText] = useState(false)
  const { all_service } = useAppSelector(state => state.service)
  const handleId = (id: string) => {
    setSearchParams({ id: `${id}` })
  }

  return (
    <section className={`container ${s.menu_service}`}>
      <nav className={s.menu_nav_service}>
        <ul>
          {
            all_service.length > 0 &&
            [...all_service].sort((a, b) => a.order - b.order).map(el => (
              <li
                key={el.id}
                onClick={() => handleId(el?.id)}
                className={query === `${el?.id}` ? s.active : s.menu_item}>
                {el?.[`service_page_title_${lang}`]}
              </li>
            ))
          }
          <NavLink to={'/service_cargo?id=0'} className={s.menu_item}>{t("header.cargo")} </NavLink>
        </ul>
      </nav>
      <div className={upText ? s.up_info : s.add_info}>
        {add_info && add_info?.length > 0 &&
          add_info?.map(el => (
            <div key={el.id} className={s.info_card}>
              {
                el?.[`service_page_content_title_${lang}`] &&
                <h2>{el?.[`service_page_content_title_${lang}`]} </h2>
              }
              <p>{el?.[`service_page_content_body_${lang}`]} </p>
            </div>
          ))
        }
      </div>
      <SlArrowDown onClick={() => setUpText(!upText)} className={upText ? s.arr_up : s.arr_down} />
    </section >
  );
};

export default MenuService;