import React, { FC, useEffect, useState } from 'react';
import s from './Consultation.module.scss'
import ContactIcons from '../Bghome/ContactIcons/ContactIcons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { fetchByHomeInfo } from '../../../store/slice/infoSlice';

const Consultation: FC = () => {
    const [contact, setContact] = useState<boolean>(false);
    const { home } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()
    const { i18n, t } = useTranslation()
    const lang = i18n.language;
    useEffect(() => {
        dispatch(fetchByHomeInfo())
    }, [dispatch])
    return (
        <div className={s.consultation}>
            <div className={`container ${s.consultationBox}`}>
                <h2 className={s.title}>{home?.[`free_consultation_title_${lang}`]}</h2>
                <p className={s.paragraf}>{home?.[`free_consultation_description_${lang}`]}</p>
                <button onClick={() => setContact(!contact)} className={s.btn}>{t('header.btn')}</button>
            </div>
            {contact && <ContactIcons contact={contact} setContact={setContact} />}
        </div>
    );
};

export default Consultation;