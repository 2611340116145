import React, { FC, useEffect } from 'react';
import s from './Home.module.scss'
import Bghome from '../../components/Section/Bghome/Bghome';
import OurServices from '../../components/Section/OurServices/OurServices';
import WChooseUs from '../../components/Section/WChooseUs/WChooseUs';
import Acordion from '../../components/Section/Acordion/Acordion';
import Partner from '../../components/Section/Partner/Partner';
import Consultation from '../../components/Section/Consultation/Consultation';
import BtnWrapper from '../../components/Section/BtnWrapper/BtnWrapper';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import AboutCompanySwiper from '../AboutCompany/AboutCompanySwiper/AboutCompanySwiper';
import Loading from '../../components/Loading/Loading';
import { Helmet } from 'react-helmet-async';

const Home: FC = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  const { home, loading } = useAppSelector(state => state.info)
  
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])
  return (
    <>
      <Helmet>
        <meta property="og:title" content={lang === 'ru' ? 'Главная | Caravan' : lang === 'eng' ? 'Home | Caravan' : '家 | Caravan'} />
        <meta name="twitter:title" content={lang === 'ru' ? 'Главная | Caravan' : lang === 'eng' ? 'Home | Caravan' : '家 | Caravan'} />
        <link rel="canonical" href={`https://caravan-logistics.com/`} />
        <title>
          {lang === 'ru' ? 'Главная | Caravan' : lang === 'eng' ? 'Home | Caravan' : '家 | Caravan'}
        </title>
      </Helmet>
      <div className={s.home} >
        <Bghome />
        <OurServices />
        <BtnWrapper />
        <WChooseUs />
        <Acordion />
        <Partner />
        <div className={`container ${s.rewievs}`}>
          <h2>{home?.[`reviews_title_${lang}`]}</h2>
          <p>{home?.[`reviews_description_${lang}`]} </p>
          <AboutCompanySwiper video={home?.reviews_video} />
        </div>
        <Consultation />
      </div>
      {loading && <Loading />}
    </>
  );
};

export default Home;