import React, { FC, useEffect } from 'react';
import s from './ContactModal.module.scss'
import { fetchByAllInfo } from '../../../store/slice/infoSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { Link } from 'react-router-dom';
import wh from '../../../Assets/Home/whatsapp.png'
import tel from '../../../Assets/Home/communication.png'
import ins from '../../../Assets/Home/inicon.png'

const ContactModal: FC = () => {
    const { allInfo } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByAllInfo())
    }, [dispatch])
    return (
        <div className={s.icons}>
            {allInfo?.whatsapp &&
                <Link target="_blank" rel="noreferrer" to={"https://wa.me/" + allInfo?.whatsapp}>
                    <div className={s.whatssap}>
                        <img src={wh} alt="" />
                    </div>
                </Link>
            }
            {allInfo?.telegram &&
                <Link target="_blank" rel="noreferrer"
                    to={allInfo?.telegram.startsWith('https')
                        ? allInfo?.telegram : `https://t.me/${allInfo?.telegram.startsWith('+')
                            ? allInfo?.telegram : allInfo?.telegram.startsWith('@')
                                ? allInfo?.telegram.slice(1) : (allInfo && +allInfo?.telegram)
                                    ? '+' + allInfo?.telegram : allInfo?.telegram}`}>
                    <div className={s.telegramm}>
                        <img src={tel} alt="" />
                    </div>
                </Link>
            }
            {allInfo?.instagram &&
                <Link target="_blank" rel="noreferrer" to={'https://www.instagram.com/' + allInfo?.instagram}>
                    <div className={s.insta}>
                        <img src={ins} alt="" />
                    </div>
                </Link>
            }
        </div>
    );
};

export default ContactModal;