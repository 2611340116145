import React, { FC } from 'react';
import s from '../AboutCompany/AboutCompany.module.scss'
import { AboutPageBanners } from '../../store/modules';

interface FirstIN {
    subdir: AboutPageBanners
    lang: string
}
const firstDiv: FC<FirstIN> = ({ subdir, lang }) => {
    return (
        <div className={s.firstDiv} >
            <img
                src={subdir.about_page_banner_image.startsWith('http')
                    ? `https${subdir.about_page_banner_image.slice(4)}`
                    : subdir.about_page_banner_image
                }
                alt={subdir?.[`about_page_banner_text_${lang}`]} />
            <p>{subdir?.[`about_page_banner_text_${lang}`]}</p>
        </div>
    );
};

export default firstDiv;