import React, { FC, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './AboutCompanySwiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { ReviewsVideo } from '../../../store/modules';
import { useAppDispatch } from '../../../store/hooks/hooks';
import { setLinkVideo, setVideoComment } from '../../../store/slice/AboutUsSlice';
import { FcPrevious, FcNext } from "react-icons/fc";

interface aboutCompanyProps {
    video: ReviewsVideo[] | undefined;
}

const AboutCompanySwiper: FC<aboutCompanyProps> = ({ video }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef<any>(null);
    const dispatch = useAppDispatch()

    const handleVideo = (id: number) => {
        video?.filter(el => el.id === id && dispatch(setLinkVideo(el.youtube_link)))
        dispatch(setVideoComment(true))
    }

    const handleSlideChange = () => {
        if (swiperRef.current) {
            setActiveIndex(swiperRef.current.swiper.realIndex);
        }
    };

    return (
        <div className='AboutSwiper'>
            <Swiper
                ref={swiperRef}
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                initialSlide={window.screen.width <= 767 ? 0 : 1}
                slidesPerView={window.screen.width <= 767 ? 1 : video && video.length <= 2 ? 2 : 3}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
                onSlideChange={handleSlideChange}
            >
                {
                    video && video.map((el) => (
                        <SwiperSlide key={el.id}>
                            <div
                                onClick={() => handleVideo(el.id)}
                                className='iframe-container-div'
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <iframe
                                    src={el?.youtube_link.slice(0, 24) + 'embed/' + el?.youtube_link.slice(32)}
                                    title='YouTube video player'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen
                                    frameBorder="0"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    style={{ width: '100%', height: '100%' }}
                                ></iframe>
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>
            <button
                className='swiper_btns swiper_prev_btn'
                onClick={() => swiperRef.current?.swiper.slidePrev()}
                disabled={activeIndex === 0}
            ><FcPrevious /></button>
            <button
                className='swiper_btns swiper_next_btn'
                onClick={() => swiperRef.current?.swiper.slideNext()}
                disabled={activeIndex === (video ? video.length - 1 : 0)}
            ><FcNext /></button>
        </div>
    );
};

export default AboutCompanySwiper;
