import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AllInfo, HomeInfo, Question, ServiceInfo } from '../modules'
import { storesApi } from '../../axios'

type InitialState = {
  loading: boolean
  error: null | string | undefined
  allInfo: AllInfo | null
  home: HomeInfo | null
  quest: Question | null
  service: ServiceInfo[]
}

const initialState: InitialState = {
  error: null,
  loading: false,
  allInfo: null,
  home: null,
  quest: null,
  service: []
}

export const fetchByServiceInfo = createAsyncThunk<ServiceInfo[], void, { rejectValue: string }>(
  'info/fetchByServiceInfo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getServiceInfo()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data
  }
)

export const fetchByQuistionInfo = createAsyncThunk<Question, void, { rejectValue: string }>(
  'info/fetchByQuistionInfo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getQuestionInfo()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as Question
  }
)

export const fetchByAllInfo = createAsyncThunk<AllInfo, void, { rejectValue: string }>(
  'info/fetchByAllInfo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getAllInfo()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as AllInfo
  }
)

export const fetchByHomeInfo = createAsyncThunk<HomeInfo, void, { rejectValue: string }>(
  'info/fetchByHomeInfo',
  async (_, { rejectWithValue }) => {
    const res = await storesApi.getHomeInfo()
    // console.log(res);
    if (res.status !== 200) {
      return rejectWithValue('Server Error')
    }
    return res.data[0] as HomeInfo
  }
)

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {

  },
  extraReducers: ({ addCase }) => {

    addCase(fetchByServiceInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByServiceInfo.fulfilled, (state, action) => {
      state.service = action.payload
      state.loading = false
    })

    addCase(fetchByServiceInfo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })

    // =======
    addCase(fetchByQuistionInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByQuistionInfo.fulfilled, (state, action) => {
      state.quest = action.payload
      state.loading = false
    })

    addCase(fetchByQuistionInfo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })

    // =========
    addCase(fetchByAllInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByAllInfo.fulfilled, (state, action) => {
      state.allInfo = action.payload
      state.loading = false
    })

    addCase(fetchByAllInfo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })

    // =====

    addCase(fetchByHomeInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })

    addCase(fetchByHomeInfo.fulfilled, (state, action) => {
      state.home = action.payload
      state.loading = false
    })

    addCase(fetchByHomeInfo.rejected, (state, action) => {
      state.loading = false
      if (action.payload?.includes('404')) {
        state.error = 'Упс что-то пошло не так попробуйте снова!'
      } else {
        state.error = action.payload
      }
    })
  },
})

export default infoSlice.reducer
