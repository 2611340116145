import React, { FC, useEffect } from 'react';
import s from './WChooseUs.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { fetchByHomeInfo } from '../../../store/slice/infoSlice';
import imgDefault from '../../../Assets/Home/grey.png'

const WChooseUs: FC = () => {
    const { home } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()
    const lang = i18n.language;

    useEffect(() => {
        dispatch(fetchByHomeInfo())
    }, [dispatch])
    return (
        <div className={s.WCUBox}>
            <div className={`container ${s.contentBox}`}>
                <div className={s.topBox}>
                    <div className={s.leftC}>
                        <h2 className={s.title}>{home?.[`why_choose_title_${lang}`]}</h2>
                        <div className={s.itemBox}>
                            {
                                home && home?.why_choose?.length > 0 &&
                                home.why_choose.map(el => (
                                    <div className={s.item} key={el.id}>
                                        <img
                                            className={s.img}
                                            src={el.why_image.startsWith('http')
                                                ? `https${el.why_image.slice(4)}` : el.why_image}
                                            alt={el.why_title} />
                                        <h3 className={s.subTitle}>{el.why_title}</h3>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={s.rightC}>
                        <img src={home?.why_choose_images[0]?.why_image.startsWith('http')
                            ? `https${home?.why_choose_images[0]?.why_image.slice(4)}`
                            : home?.why_choose_images[0]?.why_image || imgDefault} alt="" />
                    </div>
                </div>
                <div className={s.bottomBox}>
                    <div className={s.imgL}>
                        <img src={home?.why_choose_images[1]?.why_image.startsWith('http')
                            ? `https${home?.why_choose_images[1]?.why_image.slice(4)}`
                            : home?.why_choose_images[1]?.why_image || imgDefault} alt="" />
                    </div>
                    <div className={s.imgR}>
                        <img src={home?.why_choose_images[2]?.why_image.startsWith('http')
                            ? `https${home?.why_choose_images[2]?.why_image.slice(4)}`
                            : home?.why_choose_images[2]?.why_image || imgDefault} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WChooseUs;