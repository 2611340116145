import React, { FC, useEffect } from 'react';
import s from './Gallery.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByGalery, fetchByVideo } from '../../store/slice/GalerySlice';
import { useTranslation } from 'react-i18next';
import GaleryCard from './GaleryCard';
import GalerySwiperFIrst from './GalerySwiper/GalerySwiperFIrst';
import Loading from '../../components/Loading/Loading';
import { Helmet } from 'react-helmet-async';
const Gallery: FC = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  const { galery, video, loading } = useAppSelector(state => state.galeryTs)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchByGalery())
    dispatch(fetchByVideo())
  }, [dispatch])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }, [])

  return (
    <>
      <Helmet>
        <meta property="og:title" content={lang === 'ru' ? 'Галерея | Caravan' : lang === 'eng' ? 'Gallery | Caravan' : '畫廊 | Caravan'} />
        <meta name="twitter:title" content={lang === 'ru' ? 'Галерея | Caravan' : lang === 'eng' ? 'Gallery | Caravan' : '畫廊 | Caravan'} />
        <link rel="canonical" href={`https://caravan-logistics.com/gallery`} />
        <title>
          {lang === 'ru' ? 'Галерея | Caravan' : lang === 'eng' ? 'Gallery | Caravan' : '畫廊 | Caravan'}
        </title>
      </Helmet>
      <div className='container'>
        <div className={s.Galery}>
          <h2 className={s.galeryH}>{galery?.[`photo_gallery_title_${lang}`]}</h2>
          <div className={s.CardParents}>
            {
              galery && galery?.photo_gallery_images.length > 0 &&
              galery?.photo_gallery_images.map((el, i) => (
                <GaleryCard key={i} {...el} />
              ))
            }
          </div>
          {
            video && video?.video_blocks.length > 0 &&
            <div className={s.Video}>
              <h2 className={s.VideoH}>{video?.[`vido_gallery_title_${lang}`]}</h2>
              <GalerySwiperFIrst lang={lang} videoFirst={video?.video_blocks[0]} />
              <GalerySwiperFIrst lang={lang} videoFirst={video?.video_blocks[1]} />
            </div>
          }
        </div>
      </div>
      {loading && <Loading />}
    </>
  );
};

export default Gallery;